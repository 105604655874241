<template>
  <div class="page-bread">
    <img :src="iconSrc" style="width:22px;height:22px;margin:0px 10px;border-radius:3px;" v-if="!hideIcon"/>
    <span>{{text}}</span>
    <span class="right-text" v-if="rightText!==''">{{rightText}}</span>
  </div>
</template>
<script>
export default {
  name:'page-bread',
  props:['img','text','icon','rightText','hideIcon'],
  data(){
    return{
      iconSrc:this.img?this.img():this.icon()
    }
  },
  methods: {
    // icon() {}
  }
}
</script>
<style scoped>
.page-bread{
  border-radius: 5px;
  background: #04b8ed;
  color:#fff;
  display: flex;
  align-items: center;
  height:45px;
  /* margin:10px; */
}
.right-text{
  flex-grow: 1;
  text-align: right;
  padding-right: 10px;
}
</style>